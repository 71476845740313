<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatars/13-small.png')"
            text="CCCC"
            variant="`light-success"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.username }}</span>
            </div>
            <!-- <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div> -->
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{Number(userData.balance.current).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
              </h5>
              <small>Balance</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{Number(userData.balance.total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
              </h5>
              <small>Total Balance</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="status[0][userData.block]">
                {{status[1][userData.block]}}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Zone Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="userData.bandwidth.enable ? 'success': 'danger'">
                {{userData.bandwidth.enable ? 'Enabled' : 'Disabled'}}
              </b-badge>
              <b-button
                  v-if="userData.bandwidth.enable"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon"
                  v-b-tooltip.hover.top="'Disable'"
                  @click="statusZone(false)"
                >
                  <feather-icon icon="PowerIcon" />
              </b-button>
              <b-button
                  v-else
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon"
                  v-b-tooltip.hover.top="'Enable'"
                  @click="statusZone(true)"
                >
                  <feather-icon icon="PowerIcon" />
              </b-button>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FacebookIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Facebook</span>
            </th>
            <td class="pb-50">
              {{ userData.facebook }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Emaild</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BarChart2Icon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data Used</span>
            </th>
            <td class="pb-50">
              {{ formatBytes(userData.bandwidth.used) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BarChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data Total</span>
            </th>
            <td class="pb-50">
              {{ formatBytes(userData.bandwidth.total) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,VBTooltip,BTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import usersManagerReposiroty from '@/api/admin/usersManagerReposiroty'

export default {
   props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,VBTooltip,BTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data(){
    return {
      status: [
        {
          false: 'light-success', true: 'light-danger',
        },
        {
          true: 'Blocked', false: 'Active',
        },
      ],
    }
  },
  methods:{
    statusZone(status){
      this.$swal({
        title: '2FA',
        text: `Plase input 2fa`,
        icon: 'warning',
        input: "text",
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value){
          usersManagerReposiroty.statusZone(this.$route.params.id, {enable: status,otp: result.value}).then(rs=>{
            if(rs.data.success){
              this.$bvToast.toast(rs.data.message, {
                title: 'Success',
                variant: 'success',
                solid: false,
              })
              this.userData.bandwidth.enable = status
            }else{
              this.$bvToast.toast(rs.data.errMsg, {
                title: 'Error',
                variant: 'danger',
                solid: false,
              })
            }
          })
        }
      })
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
  }
}
</script>

<style>

</style>
