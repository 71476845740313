<template>

    <b-card
      no-body
    >
      <b-overlay
        :show="show"
        rounded="xl"
        :variant="variant"
        :opacity="opacity"
        :blur="blur"
      >
        <b-card-header class="pb-50">
          <b-card-title >User Ports</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-button variant="success" style="margin: 5px;" @click="getPorts">Load Port</b-button>
          <b-button variant="success"  v-if="ports.length > 0 || ports_server.length > 0" style="margin: 5px;" @click="deleteAllPort">Delete All Port</b-button>
        </b-card-body>
        <b-card-body>
          <b-alert
              v-if="unvalid_ports.length > 0"
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>Detect {{ unvalid_ports.length }} unvalid port</span>
              </div>
            </b-alert>
            <b-button  v-if="unvalid_ports.length > 0" variant="success" style="margin: 5px;" @click="deleteUnvalidPort">Delete Unvalid Port</b-button>
        </b-card-body>
        <b-card-body  v-if="ports.length > 0">
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Clone From"
                label-for="h-first-name"
                label-cols-md="6"
              >
                <b-form-select :options="ports" value-field="_id" text-field="port" v-model="clones.id">

                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group
                  label="From"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-form-input
                    type="number"
                    v-model="clones.from"
                  >

                  </b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group
                  label="To"
                  label-for="h-first-name"
                  label-cols-md="3"
                >
                  <b-form-input
                    type="number"
                    v-model="clones.to"
                  >

                  </b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-button @click="clonePort" :disabled="wait_clone">
                Clone
              </b-button>
            </b-col>
            <b-col cols="10" v-if="wait_clone">
              <b-form-group
                  label="Progress"
                  label-for="h-first-name"
                  label-cols-md="2"
                  
                >
                  <b-progress :max="clones.total" height="1rem" variant="success">
                    <b-progress-bar :value="clones.index">
                      <span>Progress: <strong>{{ clones.index }} / {{ clones.total }}</strong></span>
                    </b-progress-bar>
                  </b-progress>
              </b-form-group>
            </b-col>
                </b-row>
        </b-card-body>
        <b-card-body>
            <b-row>            
              <b-col cols="12">
                <b-form-group
                  label="Filter"
                  label-cols-sm="3"
                  label-align-sm="left"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              
            </b-row>
            
    
        </b-card-body>
    
          <b-table
            striped
            hover
            ref="table"
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="show_ports"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(services)="data" width="100px">

              <!-- <b-badge :variant="s.bypass ? 'success' : 'warning'" v-for="s in data.item.services" :key="s.name">{{s.name}}</b-badge> -->
              <div style="height:50px; width: 100%; white-space: nowrap;">
                <b-badge
                  :variant="data.bypass ? 'warning' : 'success'"
                  v-for="data in data.item.services" 
                  :key="data.name"
                >
                  <!-- <feather-icon
                    :icon="data.bypass ?  'CheckIcon' : ''"
                    class="mr-25"
                  /> -->
                  <span>{{data.name}}</span>
                </b-badge>
              </div>
              
            </template>

            <template #cell(rotate)="data">
              <b-badge variant="success">{{data.item.rotate}} minutes</b-badge>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="data.item.status ? 'success' : 'danger'">{{ data.item.status ? 'On' : 'Off'}}</b-badge>
            </template>

            <template #cell(valid)="data">
              <b-badge :variant=" isValid(data.item._id) ? 'success':'danger'">{{ isValid(data.item._id) }}</b-badge>
            </template>

            <template #cell(wipport)="data">
              <div>
                {{data.item.wip && data.item.wip.used ? data.item.wip.host+':'+data.item.wip.port : 'Not used'}}
                {{data.item.wip && data.item.wip.used ? getDate(new Date(data.item.wip.date_expired)) : ''}}
              </div>
              
            </template>

            <template #cell(_id)=data>
              <div class="text-nowrap">
                <b-button
                  v-if="!isValid(data.item._id)"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-tooltip.hover.top="'Delete'"
                  @click="deletePort(data.item._id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <div class="text-nowrap">
              
              </div>
            </template>
            <template #cell(group_id)=data>
              {{ getGroupName(data.item.group_id)}}
            </template>
    
          </b-table>
    
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
    
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
      
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          </b-card-body>

    </b-overlay>
  </b-card>
  </template>
  
<script>

import {
    BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BCol, BRow,
    BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BOverlay, BTable, BFormSelect, BAlert, BPagination, BInputGroup, BInputGroupAppend, BBadge, BProgressBar, BProgress
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import RepositoryFactory from '../../../api/RepositoryFactory'
const UserManagersRepository = RepositoryFactory.get('usermanagers')

export default {
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BMediaBody,
        BMedia,
        BMediaAside,
        BAvatar,
        BDropdown,
        BDropdownItem,
        VuePerfectScrollbar,
        BTable,
        vSelect,BFormSelect,BPagination, BInputGroup, BInputGroupAppend,BBadge,
        BCol, BRow, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BOverlay,BAlert, BProgressBar, BProgress
    },
    directives: {
        Ripple,
    },
    data(){
        return {
        ports: [],
        ports_server: [],
        ports_server_id: [],
        unvalid_ports: [],
        show_ports: [],
        show: true,
        fields: [
          { key: 'port', label: 'port', sortable: true },
          { key: 'wipport', label: 'WIP Port', sortable: true },
          { key: 'keyport', label: 'key port', sortable: true },
          'valid',
          'status',
          { key: '_id', label: 'Action' },
        ],

        fields_s: [
          { key: 'port_id', label: 'port_id', sortable: true },
          { key: 'port_name', label: 'port_name', sortable: true },
          { key: '_id', label: 'Action' },
        ],
        wait_clone: false,
        
        blur: '2px',
        perPage: 20,
        pageOptions: [2,5, 10, 20, 50, 100,200,500,1000],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        variant: 'dark',
        opacity: 0.85,
        userList: [],
        selected_user: [],
        clones: {
          from: 0,
          to: 0,
          id: '',
          uid: '',
          username: '',
          index: 0,
          total: 0
        }
    }
    },
    created(){
      this.show = false
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
            .filter(f => f.sortable)
            .map(f => ({ text: f.label, value: f.key }))
        },
    },
    mounted() {
        this.totalRows = this.show_ports.length
    },
    methods: {
      getPorts(){
        this.show = true
        UserManagersRepository.fetchPortsById(this.$route.params.id).then(rs=>{
          this.ports = rs.data.ports
          this.ports_server = rs.data.port_server
          this.ports_server_id = this.ports_server.map(x=>x.port_id)
          let ports_id = this.ports.map(x=>x._id)
          this.unvalid_ports = this.ports_server.filter(x=>!ports_id.includes(x.port_id)).map(x=>{
            return {
              port_id: x.port_id,
              port_name: x.port_name
            }
          })
          this.show_ports = this.ports
          this.totalRows = this.show_ports.length
          this.show_ports.sort((a,b)=>{
            return new Date(b.date_created) - new Date(a.date_created);
          })
          this.show = false
        })
      },
      isValid(id){
        return this.ports_server_id.includes(id)
      },
      deleteUnvalidPort(){
        let ids = this.unvalid_ports.map(x=>x.port_id)
        UserManagersRepository.deleteUnValidPort(ids).then(rs=>{
          
        })
      },
      async clonePort(){
        this.clones.index = 0
        this.clones.total = this.clones.to - this.clones.from

        this.wait_clone = true
        
        const id = this.ports.find(x=>x._id == this.clones.id)

        

        const timer = ms => new Promise(res => setTimeout(res, ms))
        for(let i = this.clones.from;i<=this.clones.to;i++){
          this.clones.index++ 
         
          let listK = this.ports.filter(x=>x.keyport==id.keyport && x.nameport).map(x=>x.nameport).sort().pop()
          let namep = parseInt(listK.split('_')[1])+1
          let nameport = id.nameport.split('_')[0]+'_'+namep

          await UserManagersRepository.clonePort(this.$route.params.id, {
            id: this.clones.id,
            port: i,
            nameport: nameport
          })
          await timer(3000)
        }
        this.wait_clone = false
        this.getPorts()
      },
      onShowActive(e){
            if(e){
            this.show_plans = this.plans.filter(x=>x.active === true)
            this.totalRows = this.show_plans.length
            }else{
            this.show_plans = this.plans
            this.totalRows = this.show_plans.length
            }
      },
      filterByUser(e){
  
            if(this.selected_user.length > 0){
            const userL = this.selected_user.map(x=>x.username)
            this.show_plans = this.plans.filter(x=>userL.includes(x.username))
            this.totalRows = this.show_plans.length
            }else{
            this.show_plans = this.plans
            this.totalRows = this.show_plans.length
            }
      },
      onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
      },
      formatDateTime(date){
            if(!date)
            return ""
            let d = new Date(date)
    
            return d.toLocaleString('vi-VN')
            let options = { year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false}
            return new Intl.DateTimeFormat('en-US', options).format(d)
      },
      clearFind(){
            this.show_plans = this.plans
            this.totalRows = this.show_plans.length
      },
      formatBytes(bytes) {
        let spector = ""
        if(bytes < 0){
            spector = "-"
            bytes = bytes * -1
        }
            var marker = 1000; // Change to 1000 if required
            var decimal = 2; // Change as required
            var kiloBytes = marker; // One Kilobyte is 1024 bytes
            var megaBytes = marker * marker; // One MB is 1024 KB
            var gigaBytes = marker * marker * marker; // One GB is 1024 MB
            var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
            // return bytes if less than a KB
            if(bytes < kiloBytes) return spector+bytes + " Bytes";
            // return KB if less than a MB
            else if(bytes < megaBytes) return spector+(bytes / kiloBytes).toFixed(decimal) + " KB";
            // return MB if less than a GB  ``
            else if(bytes < gigaBytes) return spector+(bytes / megaBytes).toFixed(decimal) + " MB";
            // return GB if less than a TB
            else if(bytes < teraBytes) return spector+(bytes / gigaBytes).toFixed(decimal) + " GB";
            
            else return spector+(bytes / teraBytes).toFixed(decimal) + " TB";
      },
      getDate(c){
        let d = new Date(c)
        let t = d.getTime() - new Date(Date.now()).getTime()


        let diffDay =  (t / (1000 * 3600 * 24)).toFixed(0)
        return diffDay+" days"
      },
      deletePort(id){
        UserManagersRepository.deletePort(id).then(rs=>{
  
          let index = this.ports.findIndex(x=>x._id == id)
          if(index > -1){
            this.ports.splice(index,1)
            this.show_ports = this.ports
          }
        })
      },
      deleteAllPort(){
        this.$swal({
        title: 'Are you sure?',
        text: `All port will be delete all`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Continue',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //this.ports_server_id = this.ports_server.filter(x=>x.wip && !x.wip.used). map(x=>x.port_id)
          UserManagersRepository.deleteAllPort(this.$route.params.id, this.ports_server_id).then(rs=>{
            if(rs.data.success){
              this.getPorts()
            }else{
              this.$bvToast.toast('Error: '+x.data.errMsg, {
                title: 'Error',
                variant: 'danger',
                solid: false,
              })
            }
          })
        }
      })
      }
    },  
    setup(){
        const perfectScrollbarSettings = {
            maxScrollbarLength: 5,
            wheelPropagation: false,
        }
        
    
        return {
            perfectScrollbarSettings,
        }
    },
        
    }
</script>
<style>
    .noti {
    max-height: 400px;
    }
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>