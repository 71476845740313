<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-1"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          size="sm"
          @click="showModal"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">New Ticket</span>
        </b-button>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(_id)="data">
        <!-- <b-button
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="direct(data.item._id)"
        >
          <b-link
          View
        </b-button> -->
         <b-link
            class="ml-25"
            :href="'/a/support/helpdesk/'+data.item._id"
            target="_blank"
          >View</b-link>
      </template>
      <template #cell(from)="data">
        <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="require('@/assets/images/portrait/small/avatar-s-4.jpg')"
                :variant="`light-success`"
              />
            </template>
            <h6>{{data.item.user}}</h6>
            <small class="text-muted">User</small>
          </b-media>
      </template>
      <template #cell(created_at)="data">
        {{getTimeInterval(new Date(data.item.created_at))}}
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ data.value }}
        </b-badge>
      </template>
      
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      id="modal-add-ticket"
      title="New Ticket"
      size="lg"
      ok-title="Send"
      cancel-variant="outline-secondary"
      @ok="onSubmit"
    >
      <b-form>
        <b-form-group
          label="Subject *"
          label-for="Subject"
        >
        <validation-provider
            #default="{ errors }"
            name="Subject"
            rules="required"
          >
          <b-form-input
            id="Subject"
            v-model="ticketForm.subject"
            placeholder="Enter Subject"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Message *"
          label-for="Message"
        >
        <validation-provider
            #default="{ errors }"
            name="Message"
            rules="required"
          >
          <b-form-textarea
            id="Message"
            v-model="ticketForm.message"
            placeholder="Enter Message"
            rows="7"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BMedia, BLink, BCard, BFormTextarea, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, } from 'vee-validate'
import RepositoryFactory from '../../../api/RepositoryFactory'
const Supports = RepositoryFactory.get('suports')

import {  required } from '@validations'

const ticketObj = {
  subject: "",
  message: "",
}
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BLink,
    BCard,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 20,50,100],
      totalRows: 100,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      items:[],
      fields: [
        { key: 'from', label: 'From', sortable: true },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'created_at', label: 'Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: '_id', label: 'ACtion',
        },
      ],
      status: [{
        1: 'Success', 2: 'Pending', 3: 'Rejected', 4: 'Refund',
      },
      {
        1: 'light-success', 2: 'light-warning', 3: 'light-danger', "New": 'light-info',
      }],
      ticketForm: Object.assign({},ticketObj),
      required
    }
  },
  created(){
    Supports.fetchById(this.$router.currentRoute.params.id).then(rs=>{
      this.items = rs.data.data
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    mountData(){
      this.totalRows = this.items.length
    },
    showModal(){
      this.$bvModal.show('modal-add-ticket')
    },
    direct(id){
      this.$router.push('/a/support/helpdesk/'+id)
    },
    onSubmit(event){
      event.preventDefault()
      Supports.createTicket(this.ticketForm).then(rs=>{

        let data = rs.data.data
        delete data['message']
        this.items.push(data)
      }).catch(e=>{

      })
    },
    getTimeInterval(date) {
      let seconds = Math.floor((Date.now() - date) / 1000);
      let unit = "second";
      let direction = "ago";
      if (seconds < 0) {
        seconds = -seconds;
        direction = "from now";
      }
      let value = seconds;
      if (seconds >= 31536000) {
        value = Math.floor(seconds / 31536000);
        unit = "year";
      } else if (seconds >= 86400) {
        value = Math.floor(seconds / 86400);
        unit = "day";
      } else if (seconds >= 3600) {
        value = Math.floor(seconds / 3600);
        unit = "hour";
      } else if (seconds >= 60) {
        value = Math.floor(seconds / 60);
        unit = "minute";
      }
      if (value != 1)
        unit = unit + "s";
      return value + " " + unit + " " + direction;
    }
  },
}
</script>
