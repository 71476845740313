<template>

  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Activity</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
   
    <b-card-body>
       <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="noti scroll-area"
      >
      <div
        v-for="transaction in data"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.d_type }}
            </h6>
            <small>{{ transaction.data.method }}</small>
          </b-media-body>
        </b-media>
        <b-media no-body>
          <b-media-body>
             <div
                v-if="transaction.data.amount"
                class="font-weight-bolder"
                :class="transaction.class"
              > 
                {{Number(transaction.data.amount).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
              </div>
              <div
                v-else
                class="font-weight-bolder"
                :class="transaction.class"
              > 
                {{transaction.data.status}}
              </div>
            <small>{{ formatDateTime(new Date(transaction.create_at)) }}</small>
          </b-media-body>
        </b-media>
      </div>
        </vue-perfect-scrollbar>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      config: {
        type:{
          1: "Balance"
        },
        icon:{
          1: "DollarSignIcon"
        }
      }
    }
  },
  methods: {
    getamountVariant(amount){
      if(type == 1)
        return "light-danger"
    },
    formatDateTime(date){
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(date)
    }
  },  
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 5,
      wheelPropagation: false,
    }
    

    return {
      perfectScrollbarSettings,
    }
  }
}
</script>
<style>
  .noti {
    max-height: 400px;
  }
</style>

